/**
 * @description
 * SVG icons in this file are exported from Figma.
 */

type IconProps = React.SVGProps<SVGSVGElement>;

const defaultIconProps: IconProps = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "1em",
  fill: "currentColor",
};

export const ArrowDropdown: React.FC<IconProps> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <path d="M7 10L12 15L17 10H7Z" />
  </svg>
);

export const ChevronLeft: React.FC<IconProps> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <path d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z" />
  </svg>
);

export const Success: React.FC<IconProps> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" />
  </svg>
);

export const Information: React.FC<IconProps> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z" />
  </svg>
);

export const Alert: React.FC<IconProps> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z" />
  </svg>
);

export const Error: React.FC<IconProps> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <path d="M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z" />
  </svg>
);

export const Close: React.FC<IconProps> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
  </svg>
);

export const Popout: React.FC<IconProps> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <path d="M19 19H5V5H12V3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V12H19V19ZM14 3V5H17.59L7.76 14.83L9.17 16.24L19 6.41V10H21V3H14Z" />
  </svg>
);

export const Event: React.FC<IconProps> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <path d="M10.825 18.3L7.375 14.875L8.425 13.825L10.825 16.175L15.425 11.575L16.475 12.65L10.825 18.3ZM4.5 22C4.1 22 3.75 21.85 3.45 21.55C3.15 21.25 3 20.9 3 20.5V5C3 4.6 3.15 4.25 3.45 3.95C3.75 3.65 4.1 3.5 4.5 3.5H6.125V2H7.75V3.5H16.25V2H17.875V3.5H19.5C19.9 3.5 20.25 3.65 20.55 3.95C20.85 4.25 21 4.6 21 5V20.5C21 20.9 20.85 21.25 20.55 21.55C20.25 21.85 19.9 22 19.5 22H4.5ZM4.5 20.5H19.5V9.75H4.5V20.5ZM4.5 8.25H19.5V5H4.5V8.25ZM4.5 8.25V5V8.25Z" />
  </svg>
);

export const Backward: React.FC<IconProps> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z" />
  </svg>
);

export const Document: React.FC<IconProps> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <path d="M7.975 17.75H16.025V16.25H7.975V17.75ZM7.975 13.5H16.025V12H7.975V13.5ZM5.5 22C5.1 22 4.75 21.85 4.45 21.55C4.15 21.25 4 20.9 4 20.5V3.5C4 3.1 4.15 2.75 4.45 2.45C4.75 2.15 5.1 2 5.5 2H14.525L20 7.475V20.5C20 20.9 19.85 21.25 19.55 21.55C19.25 21.85 18.9 22 18.5 22H5.5ZM13.775 8.15V3.5H5.5V20.5H18.5V8.15H13.775ZM5.5 3.5V8.15V3.5V20.5V3.5Z" />
  </svg>
);

export const Help: React.FC<IconProps> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 19H11V17H13V19ZM15.07 11.25L14.17 12.17C13.45 12.9 13 13.5 13 15H11V14.5C11 13.4 11.45 12.4 12.17 11.67L13.41 10.41C13.78 10.05 14 9.55 14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 9.88 15.64 10.68 15.07 11.25Z" />
  </svg>
);

export const PlayCircle: React.FC<IconProps> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 16.5V7.5L16 12L10 16.5Z" />
  </svg>
);

export const Circle: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <circle cx="12" cy="12" r="10" />
  </svg>
);

export const RadioButtonChecked: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
    />
  </svg>
);

export const RadioButtonUnchecked: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg {...defaultIconProps} viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
    />
  </svg>
);
