import { JstDate } from "../jst-date";

/**
 * @param param ISO8601 Date|DateTime string or Date object
 * @returns "YYYY/MM/DD"
 */
export const formatDate = (param: string | Date): string => {
  return param instanceof Date
    ? JstDate.fromDate(param).formatDate()
    : JstDate.fromStr(param)?.formatDate() ?? param;
};

/**
 * @param param ISO8601 Date|DateTime or Date object
 * @returns "YYYY/MM/DD HH:mm"
 */
export const formatDateTime = (param: string | Date): string => {
  return param instanceof Date
    ? JstDate.fromDate(param).formatDateTime()
    : JstDate.fromStr(param)?.formatDateTime() ?? param;
};
