import { css } from "@emotion/react";
import { memo } from "react";

/**
 * Basically, the size of the margins is determined by multiples of 4px.
 * If this doesn't include the size that you want to use, please add it to here.
 */
type MarginSize = 4 | 8 | 12 | 16 | 20 | 24 | 28 | 32 | 36 | 40 | 48 | 56 | 64 | 72 | 80 | 88;

/**
 * Spacer component for margins that belong to no component.
 */
export const Spacer: React.FC<{
  /** size (px) */
  size: MarginSize;
  /** whether or not this is horizontal spacer */
  hr?: boolean | undefined;
}> = memo((props) => {
  const style =
    props.hr === true
      ? css`
          display: inline-block;
          width: ${props.size}px;
          height: 0;
        `
      : css`
          display: block;
          height: ${props.size}px;
        `;
  return <span css={style} />;
});
