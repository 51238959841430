import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { forwardRef } from "react";
import { Merge } from "type-fest";

export type AnchorProps = Merge<
  React.ComponentPropsWithoutRef<"a">,
  Partial<Pick<NextLinkProps, "href">>
>;

/**
 * A thin wrapper of <a /> and <NextLink />.
 * If you want to add custom styles by yourself, please use this instead of <Link />.
 */
export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>((props, ref) => {
  const { children, href, ...others } = props;
  /** avoid wrapping with <NextLink /> if href isn't set because Anchor may be used as button. */
  if (href == null) {
    return (
      <a {...others} href={href} ref={ref}>
        {children}
      </a>
    );
  }
  return (
    <NextLink {...others} href={href} ref={ref}>
      {children}
    </NextLink>
  );
});

/** <Anchor /> for new tab */
export const NewTabAnchor = forwardRef<HTMLAnchorElement, AnchorProps>((props, ref) => (
  <Anchor {...props} ref={ref} target="_blank" rel="noopener noreferrer" />
));
