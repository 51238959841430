import {
  OfficeExciseType,
  BankType,
  BankBranchType,
  BankAccountType,
  TaxationPeriodStatus,
  ProcessingMethod,
} from "../../generated/graphql-types";

const labelByOfficeExciseType: Readonly<Record<OfficeExciseType, string>> = {
  [OfficeExciseType.PrincipleTaxationBulk]: "原則課税（一括比例配分方式）",
  [OfficeExciseType.PrincipleTaxationIndividual]: "原則課税（個別対応方式）",
  [OfficeExciseType.SimplifiedTaxation]: "簡易課税",
  [OfficeExciseType.TaxFree]: "免税事業者",
};

/**
 * @param type OfficeExciseType
 * @returns 表示文字列
 */
export const formatOfficeExciseType = (type: OfficeExciseType): string => {
  return labelByOfficeExciseType[type];
};

const labelByBankType: Readonly<Record<BankType, string>> = {
  [BankType.Ginko]: "銀行",
  [BankType.Kinko]: "金庫",
  [BankType.Kumiai]: "組合",
  [BankType.Noukyo]: "農協",
  [BankType.Gyokyo]: "漁協",
};

/**
 * @param type BankType
 * @returns 表示文字列
 */
export const formatBankType = (type: BankType): string => {
  return labelByBankType[type];
};

const labelByBankBranchType: Readonly<Record<BankBranchType, string>> = {
  [BankBranchType.Honten]: "本店",
  [BankBranchType.Shiten]: "支店",
  [BankBranchType.Honsho]: "本所",
  [BankBranchType.Shisho]: "支所",
  [BankBranchType.Shuchoujo]: "出張所",
};

/**
 * @param type BankBranchType
 * @returns 表示文字列
 */
export const formatBankBranchType = (type: BankBranchType): string => {
  return labelByBankBranchType[type];
};

const labelByBankAccountType: Readonly<Record<BankAccountType, string>> = {
  [BankAccountType.Futsu]: "普通",
  [BankAccountType.Touza]: "当座",
  [BankAccountType.NozeiJunbi]: "納税準備",
  [BankAccountType.Tsuchi]: "通知",
  [BankAccountType.Betsudan]: "別段",
  [BankAccountType.Chochiku]: "貯蓄",
  [BankAccountType.Other]: "その他",
};

/**
 * @param type BankAccountType
 * @returns 表示文字列
 */
export const formatBankAccountType = (type: BankAccountType): string => {
  return labelByBankAccountType[type];
};

const labelByTaxationPeriodStatus: Readonly<Record<TaxationPeriodStatus, string>> = {
  [TaxationPeriodStatus.Creating]: "作成中",
  [TaxationPeriodStatus.Done]: "作成完了",
  [TaxationPeriodStatus.Submitted]: "提出済み",
};

/**
 * @param status TaxationPeriodStatus
 * @return display string
 */
export const formatTaxationPeriodStatus = (status: TaxationPeriodStatus): string => {
  return labelByTaxationPeriodStatus[status];
};

const labelByProcessingMethod: Readonly<Record<ProcessingMethod, string>> = {
  [ProcessingMethod.Tsumiage]: "積上げ",
  [ProcessingMethod.Warimodoshi]: "割戻し",
};

/**
 * @param status ProcessingMethod
 * @return display string
 */
export const formatProcessingMethod = (method: ProcessingMethod): string => {
  return labelByProcessingMethod[method];
};
