import { format, parseISO } from "date-fns";

/**
 * 文字列を日付に変換します。
 * タイムゾーン指定がない場合に JST として変換します。
 * ISO8601 拡張形式の特定の書式にだけ対応しています。
 * @see https://www.w3.org/TR/NOTE-datetime
 * @param str 文字列
 * @returns null=変換できなかった
 */
const parseDateWithDefaultLocaleJST = (str: string): Date | null => {
  const tryToParseDate = (str: string): Date | null => {
    const date = parseISO(str);
    return Number.isNaN(+date) ? null : date;
  };
  // YYYY-MM-DD
  if (/^\d{4}-\d\d-\d\d$/.exec(str) != null) {
    return tryToParseDate(`${str}T00:00:00+09:00`);
  }
  // YYYY-MM-DDThh:mm:ss.sss
  if (/^\d{4}-\d\d-\d\d[T ]\d\d:\d\d:\d\d(\.\d{0,3})?$/.exec(str) != null) {
    return tryToParseDate(`${str}+09:00`);
  }
  // YYYY-MM-DDThh:mm:ss.sss+TZD
  if (/^\d{4}-\d\d-\d\d[T ]\d\d:\d\d:\d\d(\.\d{0,3})?(Z|[+-]\d\d:\d\d)$/.exec(str) != null) {
    return tryToParseDate(`${str}`);
  }
  return null;
};

/** JST のオフセット */
const JST_DATE_OFFSET = -540;

/**
 * 日本時間を表現する値オブジェクト。
 * 以下の理由から専用クラスを用意しタイムゾーンが関わる処理をこのクラスに閉じ込める方針を採用した。
 * - Date や dayjs をそのまま使うとどのインスタンスが日本時間に変換されているのか混乱する可能性があるため
 * - date-fns に依存したライブラリが既にあるため dayjs を追加でインストールすることがためらわれたため
 * - タイムゾーンが関わる処理をカプセル化して影響範囲を制限したかったため
 *
 * Temporal API が普及した後はそちらに乗り換えたい。
 * https://tc39.es/proposal-temporal/docs/ja/index.html
 */
export class JstDate {
  /** ローカル時間を日本時間になるようにオフセット分移動した unix time のミリ秒 */
  readonly value: number;
  private constructor(value: number) {
    this.value = value;
  }
  /**
   * @param date ブラウザローカル時間の Date オブジェクト
   * @returns
   */
  static fromDate(date: Date): JstDate {
    const offsetMinutes = date.getTimezoneOffset() - JST_DATE_OFFSET;
    return new JstDate(date.valueOf() + offsetMinutes * 60 * 1000);
  }
  /**
   * @param str ISO8601 拡張形式の Date または DateTime
   * @returns
   */
  static fromStr(str: string): JstDate | null {
    const date = parseDateWithDefaultLocaleJST(str);
    return date == null ? null : JstDate.fromDate(date);
  }
  /**
   * @returns "YYYY/MM/DD" 形式の文字列
   */
  formatDate(): string {
    return format(this.value, "yyyy/MM/dd");
  }
  /**
   * @returns "YYYY/MM/DD HH:mm" 形式の文字列
   */
  formatDateTime(): string {
    return format(this.value, "yyyy/MM/dd HH:mm");
  }
  /**
   * @returns 日本時間の時刻
   */
  hours(): number {
    return new Date(this.value).getHours();
  }
}
