import { css } from "@emotion/react";
import { Popout as IconPopout } from "../icons";
import { AnchorProps, Anchor, NewTabAnchor } from "./anchor";

const styles = {
  link: css`
    display: inline;
    font-size: inherit;
    line-height: inherit;
    color: #3b7de9;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  `,
  icon: css`
    display: inline;
    font-size: inherit;
    color: inherit;
    vertical-align: text-bottom;
  `,
} as const;

export type LinkProps = Omit<AnchorProps, "ref">;

export const Link: React.FC<LinkProps> = (props) => {
  const { children, ...others } = props;
  return (
    <Anchor {...others} css={styles.link}>
      {children}
    </Anchor>
  );
};

export const NewTabLink: React.FC<LinkProps> = (props) => {
  const { children, ...others } = props;
  return (
    <NewTabAnchor {...others} css={styles.link}>
      {children}
      <IconPopout css={styles.icon} />
    </NewTabAnchor>
  );
};
